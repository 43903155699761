<template>
  <div>
    <div class="text-h6 font-weight-bold mb-4">
      Audience Growth
    </div>

    <v-card class="border-radius-1em box-shadow-soft">
      <v-card-text>
        <!-- show the options to switch -->
        <v-tabs
          show-arrows
          @change="(index) => selectedTabIndex = index"
        >
          <v-tab
            v-for="(item, index) in filteredSeries"
            :key="'series-app-' + index"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>

        <!-- render the chart component -->
        <apex-chart
          type="line"
          height="220"
          :options="chartOptions"
          :series="[filteredSeries[selectedTabIndex]]"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// Import node packages
import ApexChart from 'vue-apexcharts'
import merge from 'lodash/merge'

// Import default configuration object
import apexChartBaseOptions from '@/json/apexChartBaseOptions.js'

// Export the SFC
export default {
  // Name of the component
  name: "AudienceGrowth",

  // Accept incoming data from parent
  props: {
    platform: {
      type: String,
      required: true,
    },

    userData: {
      type: Object,
      required: true
    }
  },

  // Register the components
  components: {
    ApexChart
  },

  // Define local data variables
  data: () => ({
    selectedTabIndex: 0,
  }),

  // Define local readonly computable variables
  computed: {
    /**
     * Get the series data for apex-charts
     *
     * @returns {Array}
     */
    series() {
      return [
        {
          name: "Followers",
          data: this.userData.data.user_profile.stat_history.map((item) => {
            return [
              dayjs(`${item.month}-01`).unix() * 1000, //timestamp in milliseconds
              item.followers
            ]
          }),
        },
        {
          name: "Followings",
          data: this.userData.data.user_profile.stat_history.map((item) => {
            return [
              dayjs(`${item.month}-01`).unix() * 1000, //timestamp in milliseconds
              item.following
            ]
          }),
        },
        {
          name: "Likes",
          data: this.userData.data.user_profile.stat_history.map((item) => {
            return [
              dayjs(`${item.month}-01`).unix() * 1000, //timestamp in milliseconds
              item.avg_likes
            ]
          }),
        },
        {
          name: "Views",
          data: this.userData.data.user_profile.stat_history.map((item) => {
            return [
              dayjs(`${item.month}-01`).unix() * 1000, //timestamp in milliseconds
              item.avg_views
            ]
          }),
        }
      ]
    },

    /**
     * Show the possible series data based on the selected platform
     *
     * @returns {Array}
     */
    filteredSeries() {
      const map = {
        instagram: ["followers", "followings", "likes"],
        tiktok: ["followers", "likes", "views"],
        youtube: ["followers", "likes", "views"]
      }

      return this.series.filter((item) => map[this.platform].includes(item.name.toLowerCase()))
    },

    /**
     * Get the chart configurations object
     *
     * @returns {object}
     */
    chartOptions() {
      return merge(apexChartBaseOptions(), {
        chart: {
          offsetX: 0,
        },
        grid: {
          padding: {
            top: 5,
            right: 20,
            bottom: 5,
            left: 20,
          },
        },
        xaxis: {
          type: "datetime",
          title: {
            text: "Period",
          },
        },
        yaxis: {
          labels: {
            formatter: (seriesName) =>
              nFormatter(seriesName).toLocaleUpperCase(),
          },
          type: "numeric",
          title: {
            text: "Count",
          },
        },
        stroke: {
          width: 7,
        },
        colors: ["#8bc34a"],
        fill: {
          opacity: 5,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (y) => {
              if (typeof y !== "undefined") {
                return `${nFormatter(y.toFixed(0))}`;
              }
              return y;
            },
          },
        },
      })
    }
  }
}
</script>
